import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCountryData } from "../../features/county/countrySlice";
import Icon from "../Icon";

const PhoneDropDown = ({ setSelectedCountry, selectedCountry }) => {
  const dispatch = useDispatch();
  const { countries } = useSelector((store) => store.country);

  useEffect(() => {
    dispatch(getCountryData());
  }, []);

  const [openDropdown, setOpenDropdown] = useState(false);

  const handleCheckboxChange = (item) => {
    console.log(item);
    setSelectedCountry({
      cca2: item?.cca2,
      idd: item?.idd?.root + item?.idd?.suffixes[0],
      flag: item?.flags?.svg,
      currency: Object.keys(item?.currencies)[0],
    });
  };

  return (
    <div className="countryCodes">
      <div
        className="selectbox"
        onMouseOver={() => setOpenDropdown(true)}
        onMouseLeave={() => setOpenDropdown(false)}
      >
        {selectedCountry?.idd ? (
          <div className="selectbox__field">
            <div className="selected__country">
              <img src={selectedCountry?.flag} alt="cn" />
              <span className="codes">{selectedCountry?.idd}</span>
            </div>
            {/* <Icon id="down-caret" width="24" height="24" /> */}
            <Icon id="dropdown" width="16" height="11" />
          </div>
        ) : (
          <div className="selectbox__field">
            <div className="selected__country">
              <img src={"https://flagcdn.com/ng.svg"} alt="cn" />
              <span className="codes">+234</span>
            </div>
            {/* <Icon id="down-caret" width="24" height="24" /> */}
            <Icon id="dropdown" width="16" height="11" />
          </div>
        )}

        {openDropdown && (
          <div className="selectbox__dropdown">
            <ul className="select">
              {countries?.map((country, index) => (
                <li className="country-list" key={index}>
                  <img src={country?.flags?.svg} alt="cn" />
                  <p
                    className="codes"
                    onClick={() => handleCheckboxChange(country)}
                  >
                    {country?.idd?.root + country?.idd?.suffixes[0]}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default PhoneDropDown;
