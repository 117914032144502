import React, { useEffect } from "react";
import Icon from "../Icon";
import Button from "../Button";
import Modal from "./Modal";
import { useDispatch } from "react-redux";
import { resetManaulClientUpload } from "../../features/user/userSlice";
import { useNavigate } from "react-router-dom";
import { getProviderSubscribers } from "../../features/clients/clientSlice";

const AddClientSuccess = ({
  successModal,
  iconID,
  iconWidth,
  iconHeight,
  setCloseClientModal,
  setManualClientUploadModalSuccess,
  title,
  subtext,
  containerClassName,
  buttonAction,
  clients,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    setCloseClientModal(false);
  }, []);
  return (
    <Modal
      isHeader={true}
      title={"Add Clients Manually"}
      handleClose={() => {
        setManualClientUploadModalSuccess(false);
        dispatch(resetManaulClientUpload());
      }}
      show={successModal}
      containerClassName={`success-modal ${containerClassName}`}
    >
      <Icon id={iconID} width={iconWidth} height={iconHeight} />
      <h3>{title}</h3>
      <p className="success__subtext">{subtext}</p>

      <Button
        block={true}
        variant={"secondary"}
        className={"mt-40"}
        // disabled={isLoading ? true : false}
        onClick={() => {
          if (clients) {
            setManualClientUploadModalSuccess(false);
            dispatch(resetManaulClientUpload());
            dispatch(getProviderSubscribers());
          } else {
            navigate("/");
          }
        }}
      >
        Continue to dashboard
      </Button>

      <Button
        variant={"primary"}
        block
        onClick={() => {
          setCloseClientModal(true);
          setManualClientUploadModalSuccess(false);
          dispatch(resetManaulClientUpload());
        }}
        className={""}
      >
        Add new Client
      </Button>
    </Modal>
  );
};

export default AddClientSuccess;
