import React from "react";
import Modal from "./Modal";
import Button from "../Button";
import { dateFormat, fmtCurrency } from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleClientActivity,
  renewalClient,
} from "../../features/clients/clientSlice";
import { getProviderSubscribersById } from "../../features/user/userSlice";

const RenewPlanModal = ({
  openRenewModal,
  setOpenRenewModal,
  client,
  selectDuration,
  durationPrice,
  id,
}) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((store) => store.client);

  const handleClick = () => {
    const payload = {
      clientId: client?.id,
      clientType: client?.clientType,
    };
    dispatch(renewalClient(payload)).then((res) => {
      console.log(res);
      if (res?.type === "user/renewalClient/fulfilled") {
        dispatch(
          getProviderSubscribersById({ id: id, clientType: client?.clientType })
        );
        dispatch(
          getSingleClientActivity({ id: id, clientType: client?.clientType })
        );
        setOpenRenewModal(false);
      }
    });
  };
  return (
    <Modal
      handleClose={() => {
        setOpenRenewModal(false);
      }}
      title={"Fund Account"}
      show={openRenewModal}
      isModalBig={false}
      containerClassName="addClients--modal"
    >
      <div className="fundTransactions">
        <div className="fundTransactions__table">
          <div className="table--data">
            <p>Client Name:</p>
            <div className="name">
              <p>{client?.firstName + " " + client?.lastName}</p>
            </div>
          </div>

          <div className="table--data">
            <p>Plan Name:</p>
            <div className="bank">
              <p>{client?.benefit?.benefitName}</p>{" "}
            </div>
          </div>
          <div className="table--data">
            <p>Plan Duration:</p>
            <div className="bank">
              <p>{selectDuration}</p>{" "}
            </div>
          </div>
          <div className="table--data">
            <p>Amount Paid:</p>
            <div className="bank">
              <p>{fmtCurrency(durationPrice)}</p>{" "}
            </div>
          </div>
          <div className="table--data">
            <p>Status:</p>
            <div className="bank">
              <span className="ststus success">success</span>
            </div>
          </div>
          <div className="table--data">
            <p>Date:</p>
            <div className="bank">
              <p>{dateFormat(new Date(), "MMM dd, yyyy")}</p>{" "}
            </div>
          </div>
        </div>

        <div className="btn">
          <Button
            variant={"secondary"}
            block
            onClick={() => setOpenRenewModal(false)}
          >
            Cancel
          </Button>
          <Button variant={"primary"} block onClick={handleClick}>
            {isLoading ? "Loading..." : "Confirm Renewal"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default RenewPlanModal;
