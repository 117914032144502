import React, { useEffect, useState } from "react";
import { dateFormat, fmtCurrency } from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import {
  changeNavtitle,
  getProviderCredit,
  getProviderDetails,
  getRevenueData,
} from "../../features/user/userSlice";
import Spinner from "../../components/PageLoader";
import Icon from "../../components/Icon";
import Button from "../../components/Button";
import PassModal from "../../components/modal/PassModal";
import SuccessModal from "../../components/modal/SuccessModal";
import skeletonLoading from "../../assets/svgs/emptyBar.svg";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Rectangle,
  LabelList,
  ResponsiveContainer,
} from "recharts";
import {
  getClientActivity,
  getClientVisits,
  getProviderSubscribers,
  searchClient,
} from "../../features/clients/clientSlice";
import { useNavigate } from "react-router-dom";
import ActivityLog from "../../components/table/ActivityLog";
import ActiveBenefits from "../../components/ActiveBenefits";
import MobileTable from "../../components/table/MobileTable";
const filterOptions = [
  { name: "This Year", value: "THIS_YEAR" },
  { name: "This Week", value: "THIS_WEEK" },
  { name: "This Month", value: "THIS_MONTH" },
];
const Dashboard = () => {
  const [openFlancePassModal, setOpenFlancePassModal] = useState(false);
  const [debouncedTerm, setDebouncedTerm] = useState("");
  const [dropdown, setDropdown] = useState(false);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const [openFilterList, setOpenFilterList] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(filterOptions[0]);

  const { isLoading, providerCredit, revenueData } = useSelector(
    (store) => store.user
  );
  const {
    clients,
    clientVisit,
    clientActivity,
    clientSearch,
    isLoading: clLoading,
  } = useSelector((store) => store.client);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProviderCredit());
    dispatch(changeNavtitle("Dashboard"));
    dispatch(getProviderSubscribers());
    dispatch(getClientVisits());
    dispatch(getClientActivity());
    dispatch(getProviderDetails());
  }, []);

  const [storedData, setStoredData] = useState({});

  const getFromLocalStorage = () => {
    try {
      const value = localStorage.getItem("branch");

      if (value === null || value === undefined || value === "undefined") {
        return null; // Return null if the key doesn't exist
      }
      setStoredData(JSON.parse(value)); // Parse the JSON value
    } catch (error) {
      console.error("Error parsing JSON from localStorage", error);
      return null; // Return null if parsing fails
    }
  };

  useEffect(() => {
    getFromLocalStorage();
  }, [storedData]);

  useEffect(() => {
    if (selectedFilter?.value) {
      dispatch(getRevenueData(selectedFilter?.value));
    } else {
      dispatch(getRevenueData("THIS_YEAR"));
    }
  }, [selectedFilter?.value]);

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(searchClient(search));
  };

  // useEffect(() => {
  //   const handler = setTimeout(() => {
  //     setDebouncedTerm(search);
  //   }, 500);
  //   return () => {
  //     clearTimeout(handler);
  //   };
  // }, [search]);

  // useEffect(() => {
  //   if (debouncedTerm.trim() === "") return;
  //   handleSearch(debouncedTerm);
  // }, [debouncedTerm]);

  const yearlyRevenue = [
    { month: "Jan", revenue: revenueData?.barChartData?.JANUARY },
    { month: "Feb", revenue: revenueData?.barChartData?.FEBRUARY },
    { month: "Mar", revenue: revenueData?.barChartData?.MARCH },
    { month: "Apr", revenue: revenueData?.barChartData?.APRIL },
    { month: "May", revenue: revenueData?.barChartData?.MAY },
    { month: "Jun", revenue: revenueData?.barChartData?.JUNE },
    { month: "Jul", revenue: revenueData?.barChartData?.JULY },
    { month: "Aug", revenue: revenueData?.barChartData?.AUGUST },
    { month: "Sep", revenue: revenueData?.barChartData?.SEPTEMBER },
    { month: "Oct", revenue: revenueData?.barChartData?.OCTOBER },
    { month: "Nov", revenue: revenueData?.barChartData?.NOVEMBER },
    { month: "Dec", revenue: revenueData?.barChartData?.DECEMBER },
  ];

  const monthlyRevenue = [
    { month: "Week 1", revenue: revenueData?.barChartData?.week1 },
    { month: "Week 2", revenue: revenueData?.barChartData?.week2 },
    { month: "Week 3", revenue: revenueData?.barChartData?.week3 },
    { month: "Week 4", revenue: revenueData?.barChartData?.week4 },
  ];

  const weeklyRevenue = [
    { month: "Mon", revenue: revenueData?.barChartData?.MONDAY },
    { month: "Tue", revenue: revenueData?.barChartData?.TUESDAY },
    { month: "Wed", revenue: revenueData?.barChartData?.WEDNESDAY },
    { month: "Thur", revenue: revenueData?.barChartData?.THURSDAY },
    { month: "Fri", revenue: revenueData?.barChartData?.FRIDAY },
    { month: "Sat", revenue: revenueData?.barChartData?.SATURDAY },
    { month: "Sun", revenue: revenueData?.barChartData?.SUNDAY },
  ];

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {
            <div className="overview">
              <div className="overview__utils">
                <div
                  className="overview__utils--search"
                  onClick={() => setDropdown(true)}
                  onMouseLeave={() => setDropdown(false)}
                >
                  <form className="overview__utils--clients">
                    <div className="search">
                      <Icon id="search-input" width={13} height={13} />
                      <input
                        type="text"
                        placeholder="Search Client"
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                      />
                    </div>
                    <Button
                      variant={"bgBlue"}
                      borderType={"borderRight"}
                      onClick={(e) => handleSearch(e)}
                    >
                      Search
                    </Button>
                  </form>
                  {dropdown && (
                    <div className="search--dropdown">
                      {clLoading ? (
                        <div className="loader"></div>
                      ) : (
                        <>
                          {clientSearch.length > 0 && search ? (
                            <div className="results">
                              <p>Search Result</p>
                              <div className="results__body">
                                <div className="list">
                                  {clientSearch.map((client) => (
                                    <div
                                      className="listName"
                                      index={client?.id}
                                      onClick={() =>
                                        navigate(
                                          `/clients/${client?.id}?clientType=${client?.clientType}`
                                        )
                                      }
                                    >
                                      <span>
                                        {client?.firstName?.charAt(0) +
                                          client?.lastName?.charAt(0)}
                                      </span>
                                      <p>
                                        {client?.firstName +
                                          " " +
                                          client?.lastName}
                                      </p>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          ) : clientSearch.length < 1 ? (
                            <>
                              {" "}
                              <div className="empty-search">
                                <span>
                                  <Icon
                                    id="search-input"
                                    width={20}
                                    height={20}
                                  />
                                </span>
                                <h4>Client not found</h4>
                                <p>
                                  No matching client was found. Please check the
                                  details and search again
                                </p>
                              </div>
                            </>
                          ) : (
                            <div className="empty-search">
                              <span>
                                <Icon
                                  id="search-input"
                                  width={20}
                                  height={20}
                                />
                              </span>
                              <h4>Search for clients</h4>
                              <p>
                                Find and manage client details quickly by
                                searching with their name
                              </p>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </div>
                <div className="overview__utils--action">
                  <Button
                    variant={"primary"}
                    onClick={() => {
                      setOpenFlancePassModal(true);
                      getFromLocalStorage();
                    }}
                  >
                    ENTER Verification Code
                  </Button>
                </div>
              </div>
              <div className="overview__cards">
                <div className="overview__card">
                  <div className="overview__card--activeClients mb-16">
                    <span>
                      <Icon id="twoPeople" width="24" height="24" />
                    </span>
                    <div className="__text">
                      <h3>Active Clients </h3>
                      <p>
                        {" "}
                        <span>
                          {clients?.data?.statistics?.totalActiveClients}
                        </span>{" "}
                        Last 30 days
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className="overview__card--activeClients mt-16">
                    <span>
                      <Icon id="person+" width="24" height="24" />
                    </span>
                    <div className="__text">
                      <h3>New Clients </h3>
                      <p>
                        {" "}
                        <span>
                          {clients?.data?.statistics?.newClients}
                        </span>{" "}
                        Last 30 days
                      </p>
                    </div>
                  </div>
                </div>
                <div className="overview__card">
                  <div className="overview__card--balance">
                    <div className="balance">
                      <span>
                        <Icon id="bigWallet" width="60" height="60" />
                      </span>
                      <div className="__text">
                        <h3>Balance </h3>
                        <p className="mt-16">
                          <span>{fmtCurrency(providerCredit?.balance)}</span>
                        </p>
                      </div>
                    </div>
                    <p onClick={() => navigate("/transactions")}>
                      See Transaction Details{" "}
                      <Icon id="rightArrow" width="14" height="14" />
                    </p>
                  </div>
                </div>
                <div className="overview__card">
                  <div className="overview__card--members">
                    <div className="members">
                      <span>
                        <Icon id="bgPerson" width="60" height="60" />
                      </span>
                      <p onClick={() => navigate("/members")}>
                        See Members{" "}
                        <Icon id="rightArrow" width="14" height="14" />
                      </p>
                    </div>
                    <div className="__text">
                      <h3>Membership Expiring </h3>
                      <p className="mt-16">
                        <span>
                          {clients?.data?.statistics?.expiringTodayCount}
                        </span>
                        <span className="status today">Today</span>
                      </p>
                      <p className="">
                        <span>
                          {clients?.data?.statistics?.expiringThisWeekCount}
                        </span>
                        <span className="status week">This week</span>
                      </p>
                      <p className="">
                        <span>{clients?.data?.statistics?.expiredCount}</span>
                        <span className="status expired">Expired</span>
                      </p>
                    </div>
                  </div>
                  <p
                    onClick={() => navigate("/members")}
                    className="mb-members"
                  >
                    See Members <Icon id="rightArrow" width="14" height="14" />
                  </p>
                </div>
              </div>
              <div className="overview__bar">
                <div className="overview__bar--left">
                  <div className="header">
                    <div className="header--left">
                      <h3>Revenue Overview</h3>
                      <p>{fmtCurrency(revenueData?.totalRevenue)}</p>
                    </div>
                    <div className="header--right">
                      <div className="search__header">
                        <div className="search__header--container">
                          <div
                            className="search__header--filterContainer"
                            onMouseLeave={() => setOpenFilterList(false)}
                          >
                            <div
                              className="search__header--filter"
                              onMouseOver={() => setOpenFilterList(true)}
                            >
                              <p>{selectedFilter?.name || "Filter by"}</p>
                              <Icon id="down-caret" width="10" height="4" />
                            </div>
                            {openFilterList && (
                              <div className="filterList">
                                <ul>
                                  {filterOptions.map((item, index) => (
                                    <li
                                      onClick={() => {
                                        setSelectedFilter(item);
                                      }}
                                      key={index}
                                    >
                                      {item?.name}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="barchart">
                    <ResponsiveContainer width="100%" height="100%">
                      {revenueData?.totalRevenue < 1 ? (
                        <ActiveBenefits
                          icon={skeletonLoading}
                          text={`Your revenue data will appear here. Start generating transactions to see your earnings!`}
                          centerHeight={false}
                        />
                      ) : (
                        <BarChart
                          data={
                            selectedFilter?.value === "THIS_YEAR"
                              ? yearlyRevenue
                              : selectedFilter?.value === "THIS_MONTH"
                              ? monthlyRevenue
                              : weeklyRevenue
                          }
                          barSize={29}
                          margin={{ top: 15, right: 30, left: 20, bottom: 20 }}
                        >
                          <CartesianGrid
                            strokeDasharray="3 3"
                            vertical={false}
                          />
                          <XAxis
                            dataKey="month"
                            width={20}
                            axisLine={false}
                            tickLine={false}
                          />
                          <YAxis axisLine={false} tickLine={false} />
                          <Tooltip
                            content={<CustomTooltip />}
                            cursor={{ fill: "transparent" }}
                          />

                          <Bar
                            dataKey="revenue"
                            background={false}
                            fill="#005397"
                            radius={[4, 4, 4, 4]}
                            activeBar={
                              <Rectangle fill="#029AFF" stroke="#029AFF" />
                            }
                          >
                            <LabelList dataKey="revenue" position="top" />
                          </Bar>
                        </BarChart>
                      )}
                    </ResponsiveContainer>
                  </div>
                </div>
                <div className="overview__bar--right">
                  <div className="header">
                    <h3>Visits Logged</h3>
                    <p>Last 30 days</p>
                  </div>
                  <div className="body">
                    {clientVisit?.totalFlancePassVisits > 0 ? (
                      <>
                        <span>
                          <div>
                            <p>visit</p>
                            <h3>{clientVisit?.totalFlancePassVisits}</h3>
                          </div>
                        </span>{" "}
                      </>
                    ) : (
                      <>
                        {" "}
                        <div className="emptyState">
                          <Icon id="emptyVisit" width="123" height="127" />
                          <p>
                            No visits logged yet. Once members start checking
                            in, their visits will appear here.
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="overview__container mt-24">
                <h4>Activity Log</h4>
              </div>
              <>
                <div className="overview__table">
                  <div className="table-container">
                    {clientActivity.length > 0 ? (
                      <>
                        <div className="overview__table">
                          <div className="table-container">
                            <ActivityLog data={clientActivity} />
                            <div className="smaller__table">
                              {clientActivity?.map((client, index) => (
                                <MobileTable>
                                  <div className="small__dashboard--table">
                                    <div className="smallTable__right">
                                      <div className="user">
                                        <div className="">
                                          <span
                                            className={
                                              client?.clientType === "PROVIDER"
                                                ? "non-flancer"
                                                : "flancer"
                                            }
                                          >
                                            <Icon
                                              id="user"
                                              width="24"
                                              height="24"
                                            />
                                          </span>
                                        </div>
                                        <div>
                                          <h3>
                                            {client?.firstName +
                                              " " +
                                              client?.lastName}
                                          </h3>
                                          <p className="date--deet">
                                            {dateFormat(
                                              client.dateCreated,
                                              "MMM dd, yyyy hh:mm a"
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                      <p className="deets activity">
                                        {client?.activity}
                                      </p>
                                    </div>
                                    <div className="smallTable__left">
                                      <p className="amount">
                                        {fmtCurrency(client?.amount)}
                                      </p>
                                    </div>
                                  </div>
                                </MobileTable>
                              ))}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="mt-40">
                        <ActiveBenefits
                          icon={skeletonLoading}
                          text={`This section will update as transactions are completed. all details will be displayed here for easy tracking!`}
                          centerHeight={false}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </>
              <PassModal
                openFlancePassModal={openFlancePassModal}
                setOpenFlancePassModal={setOpenFlancePassModal}
                storedData={storedData}
              />
              <SuccessModal />
            </div>
          }
        </>
      )}
    </>
  );
};

export default Dashboard;

export const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.9)",
          border: "1px solid #ccc",
          borderRadius: "8px",
          padding: "12px",
          boxShadow: "0px 1px 10px 0px #029AFF80;",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
          <p
            style={{
              color: "#5C6368",
              fontWeight: "400",
              fontSize: "12px",
              display: "flex",
              alignItems: "center",
              gap: "6px",
            }}
          >
            <span
              style={{
                display: "block",
                width: "10px",
                height: "10px",
                background: "#005397",
                borderRadius: "50%",
              }}
            ></span>
            Revenue:
          </p>
          <p style={{ color: "#000B14", fontWeight: 600 }}>
            {fmtCurrency(payload[0].value)}
          </p>
        </div>
      </div>
    );
  }
  return null;
};
