import React, { useEffect, useState } from "react";
import DashboardTable from "../../components/table/DashboardTable";
import MobileTable from "../../components/table/MobileTable";
import Pagination from "../../components/table/Pagination";
import ActiveBenefits from "../../components/ActiveBenefits";
import skeletonLoading from "../../assets/svgs/nolist.svg";
import { useDispatch, useSelector } from "react-redux";
import { dateFormat, fmtCurrency, paginate } from "../../utils/helper";
import {
  getProviderCredit,
  getProviderCreditTransactions,
} from "../../features/user/userSlice";
import { useNavigate } from "react-router-dom";
import Icon from "../../components/Icon";
import Button from "../../components/Button";
import FundAmountModal from "../../components/modal/FundAmountModal";

const Transactions = () => {
  const [paginatedList, setPaginatedList] = useState([]);
  const [paginatedTransactionlist, setPaginatedTransactionlist] = useState([]);
  const [page, setPage] = useState(0);
  const [openFundModal, setOpenFundModal] = useState(false);
  const navigate = useNavigate();
  const { isLoading, providerCredit, transactions } = useSelector(
    (store) => store.user
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProviderCreditTransactions());
    dispatch(getProviderCredit());
  }, []);
  useEffect(() => {
    if (paginatedTransactionlist) {
      setPaginatedList(paginatedTransactionlist[page]);
    }
  }, [paginatedTransactionlist, page]);
  useEffect(() => {
    if (transactions) {
      setPaginatedTransactionlist(paginate(transactions));
    }
  }, [transactions?.length]);
  return (
    <>
      <div className="transactions">
        <p
          className="prev-btn"
          onClick={() => {
            navigate("/");
          }}
        >
          <Icon id="back" width="14" height="14" className="back-icon" />
          Back to Home
        </p>
        <div className="transactions__header">
          <div className="transactions__header--left">
            <span>
              <Icon id="bigWallet" width="60" height="60" />
            </span>
            <div className="balance">
              <p>BalanCe</p>
              <h4>{fmtCurrency(providerCredit?.balance)}</h4>
            </div>
          </div>
          <div className="transactions__header--right">
            <Button variant={"primary"} onClick={() => setOpenFundModal(true)}>
              <span className="nairaIcon">
                <Icon id="naira+" width="24" height="24" />
              </span>{" "}
              Fund Account
            </Button>
          </div>
        </div>

        <div className="transactions__body">
          <h2>Transaction History</h2>
          {transactions?.length >= 1 ? (
            <>
              {" "}
              <div className="overview__table">
                <div className="table-container">
                  <DashboardTable data={paginatedList} />
                  <div className="smaller__table">
                    {paginatedList?.map((user, index) => (
                      <MobileTable>
                        <div className="small__dashboard--table">
                          <div className="smallTable__right">
                            <h3>{user?.transactionType}</h3>
                            <p className="deets">{user?.transactionId}</p>
                            <p className="date--deet">
                              {dateFormat(
                                user.dateCreated,
                                "MMM dd, yyyy hh:mm a"
                              )}
                            </p>
                          </div>
                          <div className="smallTable__left">
                            <p className="amount">
                              {fmtCurrency(user?.amount)}
                            </p>
                            <span
                              className={`status ${
                                user?.operationType === "CR"
                                  ? "employee-active"
                                  : user?.operationType === "DR" ||
                                    user?.subscriptionStatus === "CANCELLED"
                                  ? "status-inactive"
                                  : "pending"
                              }`}
                            >
                              {user?.operationType === "CR"
                                ? "Credit"
                                : "Debit"}
                            </span>
                          </div>
                        </div>
                      </MobileTable>
                    ))}
                  </div>
                  <Pagination
                    itemList={paginatedTransactionlist}
                    page={page}
                    setPage={setPage}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="mt-40">
              <ActiveBenefits
                icon={skeletonLoading}
                text={`No transaction yet. They'll show here once you start`}
                centerHeight={false}
              />
            </div>
          )}
        </div>
      </div>
      {openFundModal && (
        <FundAmountModal
          setOpenFundModal={setOpenFundModal}
          openFundModal={openFundModal}
        />
      )}
    </>
  );
};

export default Transactions;
