import React from "react";
import { Input } from "../../components/forms";
import Icon from "../../components/Icon";
import Button from "../../components/Button";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { changeOldPassword } from "../../features/user/userSlice";

const Security = () => {
  const { isLoading } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const schema = Yup.object({
    currentPassword: Yup.string().required("Required"),
    password: Yup.string()
      .required("Required")
      .min(8, "Password must be at least 8 characters")
      .matches(/^(?=.*[!@#$%^&*_-])/, "Must Contain One Special Character"),
  });

  const {
    submitForm,
    handleChange,
    handleBlur,
    touched,
    values,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: {
      currentPassword: "",
      password: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(
        changeOldPassword({
          oldPassword: values.currentPassword,
          newPassword: values.password,
        })
      ).then((res) => {
        if (res.type === "user/changeOldPassword/fulfilled") {
          setFieldValue("currentPassword", "");
          setFieldValue("password", "");
        }
      });
    },
  });

  return (
    <div className="security">
      <div className="security__header">
        <h3>Change Password</h3>
        <p>
          Provide your current and new password to successfully change your
          password.
        </p>
      </div>
      <div className="security__body">
        <div className="security__body--left">
          <h4>Passwords must contain</h4>
          <ul>
            <li>
              <span>
                <Icon id={"disc"} height={"10"} width={"10"} />{" "}
              </span>{" "}
              8 or more characters
            </li>
            <li>
              {" "}
              <span>
                <Icon id={"disc"} height={"10"} width={"10"} />{" "}
              </span>{" "}
              An uppercase letter (A-Z)
            </li>
            <li>
              {" "}
              <span>
                <Icon id={"disc"} height={"10"} width={"10"} />{" "}
              </span>{" "}
              A lowercase letter (a-z)
            </li>
            <li>
              {" "}
              <span>
                <Icon id={"disc"} height={"10"} width={"10"} />{" "}
              </span>{" "}
              A lowercase letter (a-z)
            </li>
          </ul>
        </div>
        <div className="security__body--right">
          <Input
            type="password"
            id="currentPassword"
            value={values.currentPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            label="Current Password"
            name="currentPassword"
            error={
              touched.currentPassword && errors.currentPassword ? (
                <small className="error">{errors.currentPassword}</small>
              ) : null
            }
          />
          <Input
            type="password"
            id="password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            label="Password"
            name="password"
            error={
              touched.password && errors.password ? (
                <small className="error">{errors.password}</small>
              ) : null
            }
          />
          <div className="btns">
            <Button
              variant="secondary"
              type="submit"
              className="update"
              size={"md"}
              onClick={() => {
                setFieldValue("currentPassword", "");
                setFieldValue("password", "");
              }}
            >
              <>Cancel</>
            </Button>
            <Button
              variant="primary"
              type="submit"
              className="update"
              size={"md"}
              onClick={submitForm}
            >
              <>{isLoading ? "Saving..." : "Save Changes"}</>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Security;
