import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customFetch, { checkForUnauthorizedResponse } from "../../utils/axios";
import toast from "react-hot-toast";
import nprogress from "nprogress";

const initialState = {
  isLoading: false,
  clients: [],
  clientStatus: [],
  clientVisit: "",
  clientActivity: [],
  clientSearch: [],
  singleClientActivity: [],
};

export const getProviderSubscribers = createAsyncThunk(
  "user/getProviderSubscribers",
  async (_, thunkAPI) => {
    try {
      const resp = await customFetch.get("provider/clients");
      return resp?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);
export const getClientStatus = createAsyncThunk(
  "user/getClientStatus",
  async (_, thunkAPI) => {
    try {
      const resp = await customFetch.get("provider/clients/status");
      return resp?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);
export const getClientVisits = createAsyncThunk(
  "user/getClientVisits",
  async (_, thunkAPI) => {
    try {
      const resp = await customFetch.get(
        "provider/flance-pass/visit/log/total"
      );
      return resp?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);
export const getClientActivity = createAsyncThunk(
  "user/getClientActivity",
  async (_, thunkAPI) => {
    try {
      const resp = await customFetch.get(
        "provider/clients/activity?clientTypes=FLANCE,PROVIDER"
      );
      return resp?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);

export const sendClientRenewalMail = createAsyncThunk(
  "user/sendClientRenewalMail",
  async (payload, thunkAPI) => {
    try {
      const resp = await customFetch.post(
        "provider/clients/renew/email",
        payload
      );
      return resp?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);
export const sendClientsRenewalMail = createAsyncThunk(
  "user/sendClientsRenewalMail",
  async (payload, thunkAPI) => {
    try {
      const resp = await customFetch.post(
        "provider/clients/renew/email/bulk",
        payload
      );
      return resp?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);
export const renewalClient = createAsyncThunk(
  "user/renewalClient",
  async (payload, thunkAPI) => {
    try {
      const resp = await customFetch.post("provider/client/renew", payload);
      return resp?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);
export const searchClient = createAsyncThunk(
  "user/searchClient",
  async (name, thunkAPI) => {
    try {
      const resp = await customFetch.get(
        `provider/clients/search?name=${name}`
      );
      return resp?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);
export const getSingleClientActivity = createAsyncThunk(
  "user/getSingleClientActivity",
  async (payload, thunkAPI) => {
    try {
      const resp = await customFetch.get(
        `provider/clients/activity/${payload?.id}/${payload?.clientType}`
      );
      return resp?.data?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);

const clientSlice = createSlice({
  name: "client",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getProviderSubscribers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProviderSubscribers.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.clients = payload;
      })
      .addCase(getProviderSubscribers.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errMsg = payload;
      })
      .addCase(getClientStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getClientStatus.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.clientStatus = payload?.data;
      })
      .addCase(getClientStatus.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(getClientVisits.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getClientVisits.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.clientVisit = payload?.data;
      })
      .addCase(getClientVisits.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(getClientActivity.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getClientActivity.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.clientActivity = payload?.data?.content;
      })
      .addCase(getClientActivity.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(sendClientRenewalMail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendClientRenewalMail.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        toast.success(payload.message);
      })
      .addCase(sendClientRenewalMail.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(sendClientsRenewalMail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendClientsRenewalMail.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        toast.success(payload.message);
      })
      .addCase(sendClientsRenewalMail.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(renewalClient.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(renewalClient.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        toast.success(payload.message);
      })
      .addCase(renewalClient.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(searchClient.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(searchClient.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.clientSearch = payload?.data;
      })
      .addCase(searchClient.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(getSingleClientActivity.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSingleClientActivity.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.singleClientActivity = payload?.clientTransactions;
      })
      .addCase(getSingleClientActivity.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      });
  },
});

export default clientSlice.reducer;
