import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import toast from "react-hot-toast";
import Icon from "../Icon";
import Button from "../Button";
import { useDispatch, useSelector } from "react-redux";
import { madeTransferSuccess } from "../../features/user/userSlice";

const FundAmountModal = ({ setOpenFundModal, openFundModal }) => {
  const [isCopied, setIsCopied] = useState(false);
  const { isLoading } = useSelector((store) => store.user);
  const notifySuccess = (message) => toast.success(message);
  const dispatch = useDispatch();

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText("7093826533");
      setIsCopied(true);
      notifySuccess("copied");
      setTimeout(() => setIsCopied(false), 1000); // Reset the copied state after 1 seconds
    } catch (error) {
      console.error("Failed to copy text: ", error);
    }
  };
  // useEffect(() => {
  //   if (transferSuccess) {
  //     setOpenFundModal(false);
  //   }
  // }, [transferSuccess]);
  return (
    <Modal
      handleClose={() => {
        setOpenFundModal(false);
      }}
      title={"Fund Account"}
      show={openFundModal}
      isModalBig={false}
      containerClassName="addClients--modal"
    >
      <div className="fundTransactions">
        <p>
          Fund your account from any bank using the details below and get
          credited instantly.
        </p>
        <div className="fundTransactions__table">
          <div className="table--data">
            <p>Account name</p>
            <div className="name">
              <p>Flance Technologies Limited</p>
            </div>
          </div>
          <div className="table--data">
            <p>Account number:</p>
            <div className="number">
              {isCopied ? (
                <p className="copyBtn">copied!!!</p>
              ) : (
                <p className="copyBtn" onClick={handleCopy}>
                  <span>
                    <Icon id="copy" width="14" height="16" />
                  </span>{" "}
                  8216155132
                </p>
              )}
            </div>
          </div>
          <div className="table--data">
            <p>Bank:</p>
            <div className="bank">
              <p>Wema Bank</p>{" "}
            </div>
          </div>
        </div>
        <div className="hint">
          <Icon id="light-bulb" width="20" height="16" />
          <p>
            Please send the payment receipt to{" "}
            <a href="mailto:hello@flanceapp.com"> hello@flanceapp.com</a> A
            confirmation email will be sent to you afterward.
          </p>
        </div>
        <div className="btn">
          <Button
            variant={"primary"}
            onClick={() =>
              dispatch(madeTransferSuccess()).then((res) => {
                if (res?.type === "user/madeTransferSuccess/fulfilled") {
                  setOpenFundModal(false);
                }
              })
            }
          >
            {isLoading ? "Loading..." : "I have made The payment"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default FundAmountModal;
