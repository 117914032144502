import React, { useEffect, useState } from "react";
import GalleryAdd from "../assets/svgs/Gallery-Add.svg";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Input, Select } from "./forms";
import TextAreaField from "./forms/TextArea";
import { useFormik } from "formik";
import Schedule from "./Schedule.js";
import Icon from "./Icon.js";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllLgas,
  fetchAllStates,
  fetchImageLink,
  submitProviderInfo,
} from "../features/user/userSlice.js";
import toast from "react-hot-toast";
import axios from "axios";
import nprogress from "nprogress";
import { convertTo12HourFormat } from "../utils/helper.js";
import Button from "./Button.js";
import BusinessInfoHeader from "./BusinessInfoHeader.js";
import AddBusinessAddressSection from "./AddBusinessAddressSection.js";
import FeaturesSection from "./FeaturesSection.js";
import BusinessInfoImage from "./BusinessInfoImage.js";
import BusinessAccordionHeader from "./BusinessAccordionHeader.js";
import SuccessModal from "./modal/SuccessModal.js";
import PhoneDropDown from "./forms/PhoneDropDown.js";

const BusinessInfo = ({ steps, setSteps }) => {
  const [addEquipMent, setAddEquipment] = useState("");
  const [visibleImageInputs, setVisibleImageInputs] = useState(2);
  const [addAmenities, setAddAmenities] = useState("");
  const [addactivities, setAddactivities] = useState("");
  const [profileUpload, setProfileUpload] = useState("");
  const [uploadingImage, setUploadingImage] = useState(false);
  const [previewProfile, setPreviewProfile] = useState("");
  const [country, setCountry] = useState({});
  const [stateName, setStateName] = useState("");
  const [equipments, setEquipments] = useState([
    "Cardio Machines",
    "Resistance Machine",
    "Strenght Machine",
    "Yoga Mats",
  ]);
  const [amenities, setAmenities] = useState([
    "Showers",
    "Sauna",
    "Swimming Pool",
    "Hot Tub",
  ]);
  const [activities, setactivities] = useState([
    "Aerobics & Cardio",
    "Boxing",
    "Dance (Zumba, Tabata etc)",
    "Personal Training",
    "Strength training",
    "Tabata",
    "Yoga / Pilates",
  ]);
  const { allState, stateLga, userImgLink, user, isLoading } = useSelector(
    (store) => store.user
  );
  const [addImages, setAddImages] = useState([
    { image: "", isCoverphoto: false },
    { image: "", isCoverphoto: false },
    { image: "", isCoverphoto: false },
    { image: "", isCoverphoto: false },
  ]);
  const [daysOfWeek, setDaysOfWeek] = useState([
    { day: "Monday", openTime: "07:00", closeTime: "20:00", isOpen: true },
    { day: "Tuesday", openTime: "07:00", closeTime: "20:00", isOpen: true },
    { day: "Wednesday", openTime: "07:00", closeTime: "20:00", isOpen: true },
    { day: "Thursday", openTime: "07:00", closeTime: "20:00", isOpen: true },
    { day: "Friday", openTime: "07:00", closeTime: "20:00", isOpen: true },
    { day: "Saturday", openTime: "07:00", closeTime: "20:00", isOpen: true },
    { day: "Sunday", openTime: "07:00", closeTime: "20:00", isOpen: true },
  ]);

  const [congratulationsModal, setCongratulationsModal] = useState(true);
  console.log(country);

  const dispatch = useDispatch();
  const { handleChange, values, setFieldValue, submitForm } = useFormik({
    initialValues: {
      selectedState: "",
      selectedCountry: "",
      selectedLga: "",
      zipCode: "",
      streetAddress: "",
      checkedEquipment: new Array(equipments.length).fill(false),
      checkedAmenities: new Array(amenities.length).fill(false),
      checkedactivities: new Array(activities.length).fill(false),
      instaLink: "",
      membershipPrice: "",
      startPrice: "",
      websiteLink: "",
      phone: "",
    },
    onSubmit: (values) => {
      dispatch(
        submitProviderInfo({
          providerLogo: previewProfile,
          instagramLink: values?.instaLink,
          branchInfo: [
            {
              branchName: user?.data?.providerName,
              country: values?.selectedCountry,
              region: stateName,
              city: values?.selectedLga,
              zipCode: values?.zipCode,
              contactAddress: values?.streetAddress,
              contactEmail: user?.data?.email,
              contactPhoneNumbers: values?.phone,
              callingCode: country?.idd ? country?.idd : "+234",
              website: values?.websiteLink,
              startingPriceMonthly: values?.startPrice,
              membershipFee: values?.membershipPrice,
              coverPhoto: coverPhoto?.image,
              membershipFeeOccurrence: "MONTHLY",
              photos: addImages.map((img) => (img?.image ? img?.image : "")),
              equipment: equipments.filter(
                (_, index) => values.checkedEquipment[index]
              ),
              amenities: amenities.filter(
                (_, index) => values.checkedAmenities[index]
              ),
              classes: [""],
              activities: activities.filter(
                (_, index) => values.checkedactivities[index]
              ),
              operationHours: daysOfWeek
                .filter((items) => items.isOpen)
                .map((items) => ({
                  day: items.day,
                  time: `${convertTo12HourFormat(
                    items?.openTime || "9:00 AM"
                  )} - ${convertTo12HourFormat(
                    items?.closeTime || "9:00 PM"
                  )} `,
                })),
            },
          ],
          countryCodes: [country?.cca2 || "NG"],
        })
      );
    },
  });
  function removeQueryString(url) {
    return url.split("?")[0];
  }
  const coverPhoto = addImages.find((item) => item.isCoverphoto && item.image);
  useEffect(() => {
    if (values?.selectedState) {
      dispatch(fetchAllLgas(values?.selectedState));
    }
  }, [values.selectedState]);
  useEffect(() => {
    dispatch(fetchAllStates());
    dispatch(fetchImageLink());
  }, []);
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setProfileUpload(file);
    if (file) {
      uploadToAws(file, -1);
    }
  };

  useEffect(() => {
    if (values?.selectedState) {
      const findStateName = allState?.find(
        (item) => Number(item?.id) === Number(values?.selectedState)
      );
      setStateName(findStateName?.name);
    }
  }, [values?.selectedState]);

  const uploadToAws = async (fileUpload, index) => {
    nprogress.start();
    setUploadingImage(true);
    try {
      const res = await axios.put(userImgLink, fileUpload, {
        headers: {
          "Content-Type": fileUpload.type,
        },
      });
      const result = res?.config?.url;
      nprogress.done();
      toast.success("Image uploaded successfully");
      if (index === -1) {
        setPreviewProfile(removeQueryString(result));
      } else {
        setAddImages((prevArray) =>
          prevArray.map((item, idx) =>
            idx === index ? { ...item, image: removeQueryString(result) } : item
          )
        );
      }
      dispatch(fetchImageLink());
      setUploadingImage(false);
    } catch (error) {
      nprogress.done();
      setUploadingImage(false);
      dispatch(fetchImageLink());
      toast.error("Error uploading image");
      console.error("Error uploading image", error);
    }
  };

  const handleCheckboxChange = (index) => {
    setAddImages((prevArray) =>
      prevArray.map((item, idx) =>
        idx === index
          ? { ...item, isCoverphoto: true }
          : { ...item, isCoverphoto: false }
      )
    );
  };

  const handleImageMultipleChange = (event, index) => {
    const file = event.target.files[0]; // Get the selected file
    uploadToAws(file, index);
  };
  const showMoreInputs = () => {
    setVisibleImageInputs((prevVisible) =>
      Math.min(prevVisible + 2, addImages.length)
    );
  };

  return (
    <div className="businessInfo">
      <BusinessInfoHeader
        GalleryAdd={GalleryAdd}
        handleImageChange={handleImageChange}
        previewProfile={previewProfile}
        uploadingImage={uploadingImage}
      />

      <div className="businessInfo--body">
        <Accordion preExpanded={["a"]} allowZeroExpanded>
          <AccordionItem uuid="a">
            <BusinessAccordionHeader
              headerBody={
                "Detailed address of your fitness center that can be found on google maps"
              }
              headerText={"Address *"}
            />
            <AccordionItemPanel>
              <AddBusinessAddressSection
                allState={allState}
                handleChange={handleChange}
                stateLga={stateLga}
                values={values}
              />
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <BusinessAccordionHeader
              headerBody={
                "Select the equipment available at your fitness center"
              }
              headerText={"Select Equipment *"}
            />
            <AccordionItemPanel>
              <FeaturesSection
                addFeature={addEquipMent}
                features={equipments}
                name={"Equipments"}
                setAddFeature={setAddEquipment}
                setFeatures={setEquipments}
                setFieldValue={setFieldValue}
                values={values.checkedEquipment}
                checkedFeature={"checkedEquipment"}
              />
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <BusinessAccordionHeader
              headerBody={
                "Select the amenities available at your fitness center"
              }
              headerText={"Select Amenities *"}
            />
            <AccordionItemPanel>
              <FeaturesSection
                addFeature={addAmenities}
                features={amenities}
                name={"amenity"}
                setAddFeature={setAddAmenities}
                setFeatures={setAmenities}
                setFieldValue={setFieldValue}
                values={values.checkedAmenities}
                checkedFeature={"checkedAmenities"}
              />
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <BusinessAccordionHeader
              headerBody={
                "Select the activities available at your fitness center"
              }
              headerText={"Select Activities *"}
            />
            <AccordionItemPanel>
              <FeaturesSection
                addFeature={addactivities}
                features={activities}
                name={"activities"}
                setAddFeature={setAddactivities}
                setFeatures={setactivities}
                setFieldValue={setFieldValue}
                values={values.checkedactivities}
                checkedFeature={"checkedactivities"}
              />
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <BusinessAccordionHeader
              headerBody={"Select your center's opening hours and days"}
              headerText={"Opening Hours *"}
            />
            <AccordionItemPanel>
              <Schedule daysOfWeek={daysOfWeek} setDaysOfWeek={setDaysOfWeek} />
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <BusinessAccordionHeader
              headerBody={"Upload photos of your gym (min 2 and max. 4)"}
              headerText={"Fitness Center Images *"}
            />
            <AccordionItemPanel>
              <BusinessInfoImage
                addImages={addImages}
                handleCheckboxChange={handleCheckboxChange}
                handleImageMultipleChange={handleImageMultipleChange}
                showMoreInputs={showMoreInputs}
                visibleImageInputs={visibleImageInputs}
              />
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <BusinessAccordionHeader
              headerBody={
                "Adding your contact info help client connect with you easily."
              }
              headerText={"Contact "}
            />
            <AccordionItemPanel>
              <div className="add--input">
                <p>Instagram Link (Optional)</p>
                <Input
                  id="instaLink"
                  name="instaLink"
                  placeholder="Add instagram link"
                  label={"Add instagram link"}
                  value={values?.instaLink}
                  onChange={handleChange}
                />
              </div>
              <div className="add--input website">
                <p>Website (Optional)</p>
                <Input
                  id="websiteLink"
                  name="websiteLink"
                  placeholder="Add website link"
                  label={"Add website link"}
                  value={values?.websiteLink}
                  onChange={handleChange}
                />
              </div>
              <div className="add--input website">
                <p>Phone number</p>
                <div className="phoneInput">
                  <PhoneDropDown
                    setSelectedCountry={setCountry}
                    selectedCountry={country}
                  />
                  <Input
                    id="phone"
                    type="tel"
                    name="phone"
                    placeholder="Add branch phone number"
                    label={"Add branch phone number"}
                    value={values?.phone}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>

      <SuccessModal
        successModal={congratulationsModal}
        iconID={"checkSuccess"}
        buttonText={"Finish Setup"}
        subtext={`You have successfully created an account. Tap on the "Finish Setup" button to finish setting up your account and providing other information. `}
        title={`Congratulations ${user?.data?.providerName}`}
        iconWidth={64}
        iconHeight={64}
        buttonAction={() => setCongratulationsModal(false)}
      />

      {/* <button
        // onClick={() => {
        //   setFeatures([...features, addFeature]);
        //   setAddFeature("");
        // }}
        className="addNew__branch"
      >
        <span>+</span>
        Add New Branch
      </button> */}
      <Button
        block={true}
        variant={"primary"}
        className={"mt-40"}
        disabled={isLoading ? true : false}
        onClick={() => submitForm()}
      >
        Continue
      </Button>
    </div>
  );
};

export default BusinessInfo;
