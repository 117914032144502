import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./features/user/userSlice";
import countrySlice from "./features/county/countrySlice";
import clientSlice from "./features/clients/clientSlice";

export const store = configureStore({
  reducer: {
    user: userSlice,
    country: countrySlice,
    client: clientSlice,
  },
});
