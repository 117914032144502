import React from "react";

const Thead = ({ columns, handleCheckAll, checkAll }) => {
  //  ps if you want a checkbox header pass in an check as the first item on the array
  return (
    <tr>
      {columns.map((column, index) => (
        <>
          {column === "check" ? (
            <th key={index}>
              <input
                type="checkbox"
                className="check"
                checked={checkAll}
                onChange={handleCheckAll}
              />
            </th>
          ) : (
            <th key={index}>{column}</th>
          )}
        </>
      ))}
    </tr>
  );
};

export default Thead;
