import React from "react";
import Thead from "./Thead";

const Table = ({ columns, children, handleCheckAll, checkAll }) => {
  return (
    <table>
      <Thead
        columns={columns}
        handleCheckAll={handleCheckAll}
        checkAll={checkAll}
      />
      {children}
    </table>
  );
};

export default Table;
