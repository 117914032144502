import React, { useState } from "react";
import Table from "./Table";
import { dateFormat } from "../../utils/helper";
import Icon from "../Icon";

const MemberTable = ({
  data,
  setRenewalNotificationModal,
  handleCheck,
  handleCheckAll,
  setClientToSendMail,
}) => {
  const columns = [
    "check",
    "Name",
    "Membership Expiry Date",
    "Renewal Status",
    "Notify",
  ];

  const [openInfo, setOpenInfo] = useState(null);
  const handleMailClickHover = (id, actionType) => {
    if (actionType === "hover") {
      setOpenInfo(id);
    } else {
      setRenewalNotificationModal(true);
      setClientToSendMail(id);
    }
  };

  return (
    <div>
      <Table
        columns={columns}
        hasCheckBoxHeader={true}
        handleCheckAll={handleCheckAll}
      >
        {data?.map((row, index) => (
          <tr className="pointer grey-bg" key={index}>
            <td>
              <input
                type="checkbox"
                className="check"
                checked={row.isChecked}
                onChange={() => handleCheck(row?.clientId)}
              />
            </td>
            <td>{row?.firstName + " " + row?.lastName}</td>
            <td className="">
              {dateFormat(row.renewalDate, "MMM dd, yyyy hh:mm a")}
            </td>

            <td>
              <span
                className={`status ${
                  row?.renewalStatus === "Expired"
                    ? "status-inactive"
                    : row?.renewalStatus === "Today"
                    ? "status-inactive"
                    : "pending"
                }`}
              >
                <span>
                  <Icon
                    id={
                      row?.renewalStatus === "Expired"
                        ? "expiredCircle"
                        : "pendingCircle"
                    }
                    width="12"
                    height="12"
                  />
                </span>
                {row?.renewalStatus}
              </span>
            </td>
            <td>
              <div className="mailContainer">
                <span
                  className="mail"
                  onMouseOver={() =>
                    handleMailClickHover(row?.clientId, "hover")
                  }
                  onMouseLeave={() => setOpenInfo(null)}
                >
                  <Icon
                    id="blue-mail"
                    width="24"
                    height="24"
                    onClick={() => handleMailClickHover(row, "click")}
                  />
                  <div className="dropdown-mail">
                    {openInfo === row?.clientId && (
                      <div className="topnav__dropdown">
                        <div className="infobox">
                          <Icon id="infoBlue" width="24" height="24" />
                          <p>Send renewal notification via email</p>
                        </div>
                      </div>
                    )}
                  </div>
                </span>
              </div>
            </td>
          </tr>
        ))}
      </Table>
    </div>
  );
};

export default MemberTable;
