import React from "react";
import Modal from "./Modal";
import Icon from "../Icon";
import Button from "../Button";

const RenewNotificationModal = ({
  renewalNotificationModal,
  setRenewalNotificationModal,
  handleRenew,
  loading,
}) => {
  return (
    <Modal
      handleClose={() => {
        setRenewalNotificationModal(false);
      }}
      title={"Send Renewal Notification"}
      show={renewalNotificationModal}
      isModalBig={false}
      containerClassName="memberRenew--modal"
    >
      <div className="iconHead">
        <Icon id="bigInfo" width="64" height="64" />
      </div>
      <div className="note">
        <Icon id="light-bulb" width="20" height="16" />
        <p>A charge of 5 Naira will apply for each message sent.</p>
      </div>
      <div className="btn">
        <Button
          variant={"secondary"}
          block
          onClick={() => setRenewalNotificationModal(false)}
        >
          Cancel
        </Button>
        <Button variant={"primary"} block onClick={handleRenew}>
          {loading ? "Loading..." : " Send Via Email"}
        </Button>
      </div>
    </Modal>
  );
};

export default RenewNotificationModal;
