import React from "react";
import Modal from "./Modal";
import { Input, Select } from "../forms";
import { addFixedDuration, dateFormat } from "../../utils/helper";
import Button from "../Button";

const ChangePlanModal = ({
  setOpenChangeModal,
  openChangeModal,
  newDate,
  providerBenefit,
  setSelectDuration,
  setPlanId,
  planIdSelect,
  duration,
  selectDuration,
  durationPice,
  client,
  setNewDate,
  updateUserDetails,
  isloading,
}) => {
  return (
    <Modal
      isModalBig={true}
      title={"Change Plan"}
      handleClose={() => {
        setOpenChangeModal(false);
      }}
      show={openChangeModal}
      containerClassName="addClients--modal"
    >
      <div className="changeplan">
        <div className="name">
          <p>
            <span>Name: </span>
            {client?.firstName + " " + client?.lastName}
          </p>
        </div>
        <form>
          <div className="input--group">
            <div className="address--deets">
              <p>Select Plan</p>
              <Select
                options={providerBenefit.map((item) => ({
                  name: item?.benefitName,
                  value: item?.id,
                }))}
                id="planIdSelect"
                name="planIdSelect"
                placeholder={"Select Plan"}
                onChange={(e) => {
                  setPlanId(e.target.value);
                  setSelectDuration("");
                }}
                value={planIdSelect}
              />
            </div>
            <div className="address--deets">
              <p>Select Plan Duration</p>
              <Select
                options={duration.map((item) => ({
                  name: item?.duration ? item?.duration : null,
                  value: item?.duration ? item?.duration : null,
                }))}
                id="benefitDuration"
                name="benefitDuration"
                placeholder="Duration"
                onChange={(e) => setSelectDuration(e.target.value)}
                value={selectDuration}
              />
            </div>
            <div className="address--deets">
              <p>Plan Price</p>
              <Input
                id="planPrice"
                name="=planPrice"
                placeHolder="Plan Price"
                isFloatingLabel={false}
                disabled={true}
                // onChange={handleChange}
                value={durationPice}
              />
            </div>
          </div>

          <div className="input-time">
            <div className="address--deets">
              <p>Date Paid</p>
              <Input
                id="benefitPaymentDate"
                placeHolder="MM/DD/YYYY"
                isFloatingLabel={false}
                name="benefitPaymentDate"
                maxDate={new Date()}
                isDate={true}
                value={
                  newDate
                    ? newDate
                    : dateFormat(client?.benefitPaymentDate, "MMM/dd/yyyy")
                }
                //   value={values.benefitPaymentDate}
                onChange={(e) => setNewDate(e.target.value)}
              />
            </div>
            <div className="address--deets">
              <p>Renewal Date</p>
              <Input
                id="dob"
                placeHolder="jul, 12 2024"
                isFloatingLabel={false}
                name="dob"
                maxDate={new Date()}
                isDate={true}
                disabled={true}
                value={
                  (newDate || client?.benefitPaymentDate) && selectDuration
                    ? dateFormat(
                        addFixedDuration(
                          newDate ? newDate : client?.benefitPaymentDate,
                          selectDuration
                        ),
                        "MMM/dd/yyyy"
                      ) || "mm/dd/yyyy"
                    : "mm/dd/yyyy"
                }
              />
            </div>
          </div>
          <Button
            variant={"primary"}
            block
            onClick={(e) => updateUserDetails(e)}
          >
            {isloading ? "changing..." : " Change Plan"}
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default ChangePlanModal;
