import React from "react";
import Table from "./Table";
import Icon from "../Icon";
import { dateFormat, fmtCurrency } from "../../utils/helper";

const ActivityLog = ({ data }) => {
  const columns = [
    "Client Type",
    "Client name",
    "Description",
    "Amount",
    "Date",
  ];

  return (
    <>
      <Table columns={columns}>
        {data?.map((row, index) => (
          <tr className="pointer grey-bg" key={index}>
            <td>
              <span
                className={
                  row?.clientType === "PROVIDER" ? "non-flancer" : "flancer"
                }
              >
                <Icon id="user" width="24" height="24" />
              </span>
            </td>
            <td>{row?.firstName + " " + row?.lastName}</td>
            <td> {row?.activity}</td>
            <td>{fmtCurrency(row?.amount || 0)}</td>
            <td> {dateFormat(row.dateCreated, "MMM dd, yyyy")}</td>
          </tr>
        ))}
      </Table>
    </>
  );
};

export default ActivityLog;
