import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import nProgress from "nprogress";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../features/user/userSlice";
import Icon from "../components/Icon";
import Logo from "../assets/svgs/logo.svg";

const LayoutHeader = () => {
  const { user } = useSelector((store) => store.user);
  const data = user?.data;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logout = () => {
    nProgress.start();
    setTimeout(() => {
      nProgress.done();
      dispatch(logoutUser());
      navigate("/auth/login");
    }, 500);
  };

  const [openDropdown, setDropdown] = useState(false);
  return (
    <>
      <nav className={`topnav full`}>
        <div className="topnav--wrapper">
          <div className="brand">
            <img src={Logo} alt="flance-logo" />
          </div>

          <div
            className="topnav-right d-iflx al-i-c"
            onMouseOver={() => setDropdown(true)}
            onMouseLeave={() => setDropdown(false)}
          >
            <div className="avatar-circle">
              <div className="avatar-circle__placeholder iflx-center-center">
                <p className="avatar-circle__initials iflx-center-center">
                  {data?.providerName?.slice(0, 2)}
                </p>
              </div>
            </div>
            <div className="d-iflx al-i-c">
              <p className="username mr-8">{data?.providerName}</p>
              <Icon width="8px" height="5px" id="drop-icon" />
              {openDropdown && (
                <ul className="topnav__dropdown">
                  <li
                    onClick={() => {
                      navigate("/settings");
                    }}
                  >
                    <span className="">Settings</span>
                  </li>
                  <li
                    onClick={() => {
                      logout();
                    }}
                  >
                    <span className="logoutBtn">Logout</span>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </nav>
      <Outlet />
    </>
  );
};

export default LayoutHeader;
